/* eslint-disable max-len */
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableRow,
} from '@mui/material';
import moment from 'moment';
import routes from 'utils/routes';

import ShowEditOrConfirmButton from 'components/Bookings/ShowEditOrConfirmButton';
import DiscountForm from 'pages/ExternalMeetingRooms/DiscountForm';
import theme from 'styles/theme';

type BookingPricingProps = {
  isBooked: boolean | undefined;
  disabled: boolean;
  bookingData?: Query;
  isLoading?: boolean;
  buttonOff?: boolean;
  bookRoom?: () => void;
  setDiscount?: React.Dispatch<React.SetStateAction<string | null | undefined>>;
  discountCode?: DiscountCode;
  startDate: moment.Moment;
  pricing: BookingPricing;
};

const BookingPricing = ({
  isBooked,
  disabled,
  bookingData,
  isLoading,
  buttonOff,
  bookRoom,
  setDiscount,
  discountCode,
  startDate,
  pricing,
}: BookingPricingProps) => {
  const { pathname } = useLocation();

  const currentPath = pathname.split('/')[1];

  const isExternalBookings =
    currentPath === routes.main.externalBookingsReview().split('/')[1] ||
    currentPath === routes.main.externalOfficeDayBookingsReview().split('/')[1];

  const isForThisMonth = moment().isSame(startDate, 'month');

  const PRICE_FIELDS = useMemo(
    () => [
      {
        label: isForThisMonth
          ? 'Credit Balance'
          : `${startDate.format('MMMM')} balance`,
        value: `£${pricing.creditBalance.toFixed(2)}`,
        isHidden: !pricing.showCredits,
      },
      {
        label: 'Total price of room',
        value: `£${pricing.totalPriceOfRoom.toFixed(2)}`,
      },
      {
        label: 'Credit used',
        value: `-£${pricing.usedCredits.toFixed(2)}`,
        isHidden: !pricing.usedCredits,
        isBold: false,
      },
      {
        label: 'Remaining balance',
        value: `£${pricing.remainingBalance.toFixed(2)}`,
        isHidden: !pricing.usedCredits,
      },
      {
        label: `Discount (${pricing.userDiscount}%)`,
        value: `-£${pricing.discount.toFixed(2)}`,
        isHidden: pricing.creditBalance >= pricing.totalPriceOfRoom,
        isBold: false,
      },
      {
        label: 'Total',
        value: `£${pricing.due.toFixed(2)}`,
      },
    ],
    [pricing],
  );

  return (
    <Box sx={{ padding: 3 }}>
      <Table
        sx={{
          marginBottom: 2,
          [`& .${tableCellClasses.root}`]: {
            borderBottom: 'none',
            padding: '4px 8px',
            fontSize: '26px',

            [theme.breakpoints.down('md')]: {
              fontSize: '16px',
            },
          },
        }}
      >
        <TableBody>
          {PRICE_FIELDS.map(
            ({ label, value, isHidden = false, isBold = true }) => {
              if (isHidden) return null;

              return (
                <TableRow key={label}>
                  <TableCell>{label}:</TableCell>
                  <TableCell
                    sx={{
                      fontWeight: isBold ? 'fontWeightBold' : 'normal',
                    }}
                  >
                    {value}
                  </TableCell>
                </TableRow>
              );
            },
          )}
        </TableBody>
      </Table>

      {!isBooked && isExternalBookings && setDiscount && (
        <DiscountForm
          setDiscount={setDiscount}
          discountCode={discountCode}
          disabled={disabled}
        />
      )}
      <ShowEditOrConfirmButton
        isBooked={isBooked}
        disabled={disabled}
        bookingData={bookingData && bookingData}
        isLoading={isLoading}
        buttonOff={buttonOff}
        bookRoom={bookRoom}
      />
    </Box>
  );
};

BookingPricing.defaultProps = {
  bookingData: {},
  isLoading: false,
  buttonOff: false,
  bookRoom: null,
  setDiscount: null,
  discountCode: {},
};

export default BookingPricing;
